import { Tooltip, TooltipProps, useDisclosure } from '@chakra-ui/react'
import { useAppContext } from '@components/app-context'
import ChooseGnosisSafe from '@components/connect-wallet/connectors/ChooseGnosisSafe'
import { useConnectWalletContext } from '@components/contexts/ConnectWalletProvider'
import { Tag } from '@components/core'
import {
	BankIcon,
	ConnectGnosisSafeIcon,
	DisconnectGnosisSafeIcon,
	EthIcon,
	GnosisSafeIcon,
	MetamaskIcon,
	MoonIcon,
	NewWindowIcon,
	PolygonIcon,
	PortalIcon,
	SignOutIcon,
	SunIcon,
	WalletIcon,
} from '@components/icons'
import useAddOrChangeChain from '@hooks/useAddOrChangeChain'
import useBalanceString from '@hooks/useBalanceString'
import useCurrentProfile from '@hooks/useCurrentProfile'
import useLocalStorage from '@hooks/useLocalStorage'
import useLockBodyScroll from '@hooks/useLockBodyScroll'
import useLogout from '@hooks/useLogout'
import useWindowSize from '@hooks/useWindowSize'
import clsx from 'clsx'
import { isProduction, LocalStorage } from 'helpers/constants'
import { checkIsEth, getChainBlockExplorer } from 'helpers/utils'
import { useTheme } from 'next-themes'
import Link from 'next/link'
import { FC, forwardRef, useState } from 'react'
import { shortenAddress, shortenAddressWithNoDots } from 'utils'

const NavButtonTooltip: FC<TooltipProps> = ({ children, label, placement }) => {
	const { theme } = useTheme()
	return (
		<Tooltip
			label={label}
			placement={placement ?? 'right'}
			color={theme === 'light' ? '#fff' : '#605e8a'}
			bg={theme === 'light' ? '#414141' : '#161527'}
		>
			<span>{children}</span>
		</Tooltip>
	)
}

const NavButton: FC<{
	className?: string
	link?: string
	onClick?: () => void
	externalLink?: boolean
	label: string
}> = ({ className, link, onClick, children, externalLink = false, label }) => {
	if (onClick) {
		return (
			<NavButtonTooltip label={label}>
				<button
					onClick={onClick}
					className={clsx(
						className,
						'cursor-pointer rounded-full w-10 h-10 shrink-0 bg-color-2 flex items-center justify-center'
					)}
				>
					{children}
				</button>
			</NavButtonTooltip>
		)
	} else if (externalLink) {
		return (
			<NavButtonTooltip label={label}>
				<a
					target="_blank"
					rel="noopener noreferrer"
					className="rounded-full w-10 h-10 shrink-0 bg-color-2 flex items-center justify-center"
					href={link}
				>
					{children}
				</a>
			</NavButtonTooltip>
		)
	} else {
		return (
			<NavButtonTooltip label={label}>
				<Link href={link}>
					<a className="rounded-full w-10 h-10 shrink-0 bg-color-2 flex items-center justify-center relative">
						{children}
					</a>
				</Link>
			</NavButtonTooltip>
		)
	}
}

const Separator: FC<{ className?: string }> = ({ className }) => (
	<span className={clsx(className, 'w-10 border-t border-color-3')} />
)

const SideDrawer: FC<{ list: JSX.Element[] | string[] | null; show: boolean }> = ({ list, show }) => {
	return (
		<div
			className={clsx(
				show ? 'translate-x-[60px] lg:hidden' : '-translate-x-full',
				'transform transition-transform duration-300 fixed z-20 h-full w-2/3 max-w-[300px] bg-color-1 rounded-r-lg flex-col py-5',
				'text-color-7 font-normal text-[15px]'
			)}
		>
			{list?.map((item, i) => (
				<div
					key={i}
					className="flex flex-row gap-5 items-center px-5 mr-5 h-[60px] border-b last:border-b-0 border-color-3"
				>
					{item}
				</div>
			))}
		</div>
	)
}

const SideNavbar = forwardRef<HTMLDivElement, { show: boolean }>(({ show }, ref) => {
	const { theme, setTheme } = useTheme()
	const { onOpen: openConnectWalletModal } = useConnectWalletContext()
	const { data: profile } = useCurrentProfile()
	const [, width] = useWindowSize()
	const isMobile = width < 1024

	const [showGnosisSafeWalletDrawer, setShowGnosisSafeWalletDrawer] = useState(false)
	const [showWalletDrawer, setShowWalletDrawer] = useState(false)

	const toggleGnosisSafeWalletDrawer = (): void => {
		if (showWalletDrawer) setShowWalletDrawer(false)
		setShowGnosisSafeWalletDrawer((show) => !show)
	}
	const toggleWalletDrawer = (): void => {
		if (showGnosisSafeWalletDrawer) setShowGnosisSafeWalletDrawer(false)
		setShowWalletDrawer((show) => !show)
	}

	// Wallet functions
	const logout = useLogout()
	const { chainId, safeSdk } = useAppContext()
	const isEth = checkIsEth(chainId)
	const addOrRemoveChain = useAddOrChangeChain()
	const { data: nativeBalance } = useBalanceString()
	const { data: gnosisBalance } = useBalanceString({ gnosis: true })
	const [gnosisAddress, , removeGnosis] = useLocalStorage(`${LocalStorage.GNOSIS}__${chainId}`, null)
	const { isOpen: isGnosisModalOpen, onOpen: onGnosisModalOpen, onClose: onGnosisModalClose } = useDisclosure()

	useLockBodyScroll(show && isMobile)

	/**
	 * Networks
	 */
	const polyNetworks = [
		<>
			<button className="flex flex-row w-full h-full items-center gap-3" onClick={() => addOrRemoveChain(137)}>
				<PolygonIcon className="fill-color-7 w-4 h-4" />
				<span className="w-max">Matic Network</span>
			</button>
		</>,
		...(!isProduction
			? [
					// add Mumbai
					<>
						<button className="flex flex-row w-full h-full items-center gap-3" onClick={() => addOrRemoveChain(80001)}>
							<PolygonIcon className="fill-color-7 w-4 h-4" />
							<span className="w-max">Mumbai Network</span>
						</button>
					</>,
			  ]
			: []),
	]
	const ethNetworks = [
		<>
			<button className="flex flex-row w-full h-full items-center gap-3" onClick={() => addOrRemoveChain(1)}>
				<EthIcon className="shrink-0 fill-color-7 w-4 h-4" />
				<span className="w-max">Mainnet</span>
			</button>
		</>,
		...(!isProduction
			? [
					// add Ropsten
					<>
						<button className="flex flex-row w-full h-full items-center gap-3" onClick={() => addOrRemoveChain(3)}>
							<EthIcon className="shrink-0 fill-color-7 w-4 h-4" />
							<span className="w-max">Ropsten</span>
						</button>
					</>,
			  ]
			: []),
	]

	const gnosisLoggedOutItems = [
		<>
			<button onClick={onGnosisModalOpen} className="flex flex-row w-full h-full items-center gap-3">
				<ConnectGnosisSafeIcon className="shrink-0 stroke-color-7 w-5 h-5" />
				<span>Connect</span>
			</button>
		</>,
		<>
			<a href="https://gnosis-safe.io/" className="flex flex-row w-full h-full items-center gap-3">
				<GnosisSafeIcon className="shrink-0 fill-color-7 w-5 h-5" />
				<span>What is Gnosis Safe?</span>
			</a>
		</>,
	]

	const gnosisLoggedInItems = [
		<>
			<Tag
				className="h-[30px] text-gnosis"
				label={safeSdk && gnosisAddress ? shortenAddress(gnosisAddress, 4) : 'Gnosis Safe'}
			/>
			<NewWindowIcon className="stroke-color-7 w-5 h-5" />
		</>,
		<>
			<Tag
				iconLeft={<EthIcon className="shrink-0 fill-color-2" />}
				className="font-semibold bg-gnosis text-color-2"
				label={`${gnosisBalance} ${isEth ? 'ETH' : 'MATIC'}`}
				variant="secondaryInverted"
			/>
		</>,
		<>
			<button onClick={removeGnosis} className="flex flex-row w-full h-full items-center gap-3">
				<DisconnectGnosisSafeIcon className="shrink-0 stroke-color-7 w-5 h-5" />
				<span>Disconnect Gnosis Safe</span>
			</button>
		</>,
	]

	const gnosisItems = safeSdk ? gnosisLoggedInItems : gnosisLoggedOutItems
	const networks = isEth ? polyNetworks : ethNetworks
	return (
		<>
			<div className={clsx(show ? 'lg:hidden' : 'hidden', 'fixed z-20 h-full w-full bg-black opacity-75')} />
			<div ref={ref}>
				{/* Overlay for mobile view  */}
				<ChooseGnosisSafe isOpen={isGnosisModalOpen} onClose={onGnosisModalClose} />
				{/* Wallet Drawer */}
				<SideDrawer
					show={show && showWalletDrawer}
					list={[
						<>
							<MetamaskIcon className="w-5 h-5" />
							<span>Metamask Wallet</span>
						</>,
						<>
							<Tag
								className="h-[30px] truncate"
								label={profile?.user?.ens_name ?? shortenAddressWithNoDots(profile?.user?.wallet_address, 6)}
							/>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={getChainBlockExplorer(chainId) + 'address/' + profile?.user?.wallet_address}
							>
								<NewWindowIcon className="stroke-color-7 w-4 h-4" />
							</a>
						</>,
						<>
							<Tag
								iconLeft={
									isEth ? (
										<EthIcon className="w-3 h-3 shrink-0 fill-color-7" />
									) : (
										<PolygonIcon className="w-3 h-3 shrink-0 fill-color-7" />
									)
								}
								className="font-semibold"
								label={`${nativeBalance} ${isEth ? 'ETH' : 'MATIC'}`}
								variant="secondary"
							/>
						</>,
						<>
							<button className="flex flex-row w-full h-full items-center gap-3" onClick={() => logout.mutate()}>
								<SignOutIcon className="stroke-color-7 w-3.5 h-3.5" />
								<span>Sign Out</span>
							</button>
						</>,
						...networks,
					]}
				/>
				{/* Gnosis Drawer */}
				<SideDrawer
					show={show && showGnosisSafeWalletDrawer}
					list={[
						<>
							<BankIcon className="fill-gnosis stroke-gnosis shrink-0 w-5 h-5" />
							<span>Gnosis Safe Wallet</span>
						</>,
						...gnosisItems,
					]}
				/>
				{/* Sidebar */}
				<div
					className={clsx(
						// Allow toggling for mobile view
						show ? 'translate-0' : '-translate-x-full',
						'flex transform transition duration-300 z-20 lg:z-0 bg-color-0 border-r border-color-2 flex-col-reverse lg:flex-col h-screen fixed justify-between items-center py-5 w-[60px]'
					)}
					style={{ boxShadow: isMobile && show && '2px 0px 4px rgba(0, 0, 0, 0.25)' }}
				>
					<div className="flex flex-col-reverse lg:flex-col items-center gap-[17px]">
						<div className="hidden lg:flex">
							<NavButtonTooltip label="DASHBOARD">
								<a>
									<PortalIcon className="fill-color-5 hover:fill-[url(#portal_gradient)] cursor-pointer w-8 h-8" />
								</a>
							</NavButtonTooltip>
						</div>
						<Separator className="hidden lg:flex" />
						{!profile && (
							<NavButton label="CONNECT WALLET" className="hidden lg:flex" onClick={openConnectWalletModal}>
								<WalletIcon className="fill-color-7 w-5 h-5" />
							</NavButton>
						)}
						{profile?.user && <></>}
						{/* <NavButton label="EXPLORE WEB3 COMMUNITIES" link="/creators">
							<DoubleCirclesIcon className="fill-color-7 w-6 h-6" />
						</NavButton> */}
					</div>
					<div className="flex flex-col-reverse lg:flex-col items-center gap-[17px]">
						{/* <Separator /> */}
						{/* <NavButton
							label="COINVISE LEARN"
							externalLink
							link="https://www.notion.so/coinvise/Coinvise-Learn-bf6e427d95524ecea2f03ea3db7988d9"
						>
							<BookIcon className="fill-color-7 w-6 h-6" />
						</NavButton>
						<NavButton label="GET SUPPORT" externalLink link="https://discord.gg/w9YFJU8SGz">
							<DiscordIcon className="fill-color-7 w-6 h-6" />
						</NavButton> */}
						<div className="lg:hidden flex flex-col gap-[17px] items-center">
							{theme === 'dark' ? (
								<button onClick={() => setTheme('light')}>
									<SunIcon className="stroke-color-7 w-5 h-5" />
								</button>
							) : (
								<button onClick={() => setTheme('dark')}>
									<MoonIcon className="stroke-color-7 w-5 h-5" />
								</button>
							)}
							{!profile ? (
								<NavButton label="CONNECT WALLET" onClick={openConnectWalletModal}>
									<WalletIcon className="fill-color-7 w-5 h-5" />
								</NavButton>
							) : (
								<>
									<NavButton label="WALLET" onClick={toggleWalletDrawer}>
										<MetamaskIcon className="lg:hidden w-5 h-5" />
									</NavButton>
									<NavButton label="GNOSIS SAFE" onClick={toggleGnosisSafeWalletDrawer}>
										<BankIcon className="fill-gnosis stroke-gnosis lg:hidden w-5 h-5" />
									</NavButton>
								</>
							)}
							<Separator />
						</div>
					</div>
				</div>
			</div>
		</>
	)
})

SideNavbar.displayName = 'SideNavbar'
export default SideNavbar
