import { useAppContext } from '@components/app-context'
import { Button, Modal } from '@components/core'
import useCurrentUser from '@hooks/useCurrentUser'
import useLocalStorage from '@hooks/useLocalStorage'
import clsx from 'clsx'
import { LocalStorage } from 'helpers/constants'
import { CustomModalProps } from 'interfaces/interfaces'
import Image from 'next/image'
import { FC, MouseEventHandler, useState } from 'react'
import { useQuery } from 'react-query'
import { shortenAddress } from 'utils'
import { getMilliFromMinutes } from 'utils/rq-settings'

const ChooseGnosisSafe: FC<CustomModalProps> = ({ isOpen, onClose }) => {
	const { safeService, chainId } = useAppContext()
	const { data: user } = useCurrentUser()

	const [gnosisAddress, setGnosisAddress] = useLocalStorage(`${LocalStorage.GNOSIS}__${chainId}`, null)

	const { data: safes } = useQuery(
		['owner-safes', user?.publicAddress, chainId],
		() => safeService.getSafesByOwner(user.publicAddress).then((data) => data.safes),
		{
			enabled: Boolean(user && chainId && safeService),
			staleTime: Infinity,
			cacheTime: getMilliFromMinutes(2),
		}
	)

	const [selectedSafe, setSelectedSafe] = useState<string>()

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose()
				setSelectedSafe(null)
			}}
			size="lg"
		>
			<div className="w-full flex flex-col items-center">
				<Image src="/images/Wallets/gnosis-safe-home.svg" height={40} width={40} />
				<h1 className="text-2xl font-semibold text-color-8 mt-2">Connect Gnosis Safe</h1>
			</div>
			{gnosisAddress ? (
				<div className="w-full border border-color-4 rounded-2xl flex flex-col p-8 mt-8 overflow-y-auto gap-y-8">
					<div className="w-full border border-color-4 rounded-2xl flex items-center justify-between p-4">
						<div className="flex items-center gap-x-4">
							<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M20.5781 10.1885C18.6001 10.1877 16.6663 10.7782 15.0213 11.8852C13.3763 12.9923 12.094 14.5662 11.3365 16.4078C10.5791 18.2495 10.3805 20.2762 10.7659 22.2317C11.1513 24.1871 12.1034 25.9834 13.5018 27.3934C14.9002 28.8034 16.682 29.7637 18.6219 30.153C20.5618 30.5422 22.5727 30.3428 24.4002 29.5801C26.2276 28.8173 27.7897 27.5254 28.8886 25.8678C29.9876 24.2102 30.5742 22.2613 30.5742 20.2677C30.574 17.5953 29.5209 15.0323 27.6464 13.1422C25.772 11.2522 23.2295 10.1897 20.5781 10.1885ZM27.8969 20.818H23.2531C23.1147 21.5165 22.7217 22.1374 22.1516 22.5583C21.5814 22.9791 20.8755 23.1693 20.1731 23.0914C19.4707 23.0135 18.8227 22.6732 18.3569 22.1375C17.8912 21.6018 17.6415 20.9096 17.6572 20.1975C17.6728 19.4854 17.9526 18.8051 18.4414 18.2906C18.9301 17.7762 19.5924 17.4651 20.2976 17.4185C21.0027 17.372 21.6996 17.5935 22.2507 18.0394C22.8019 18.4852 23.1672 19.123 23.2748 19.827H27.8931C28.0234 19.827 28.1485 19.8792 28.2407 19.9721C28.3329 20.065 28.3847 20.1911 28.3847 20.3225C28.3847 20.4539 28.3329 20.58 28.2407 20.6729C28.1485 20.7658 28.0234 20.818 27.8931 20.818H27.8969Z"
									className="fill-color-8"
								/>
								<rect x="1.07422" y="0.767578" width="39" height="39" rx="19.5" className="stroke-color-4" />
							</svg>
							<h1 className="rounded-full bg-color-3 text-color-8 font-semibold text-lg py-1 px-2">
								{shortenAddress(gnosisAddress, 4)}
							</h1>
						</div>
						<Image src="/images/check.svg" className="flex" width={18} height={18} />
					</div>
					<div className="w-full border border-color-4 rounded-2xl flex gap-x-4 p-4">
						<span>
							<svg
								width="26"
								height="26"
								viewBox="0 0 26 26"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="fill-color-4"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M13.0742 25.2676C19.9778 25.2676 25.5742 19.6711 25.5742 12.7676C25.5742 5.86402 19.9778 0.267578 13.0742 0.267578C6.17066 0.267578 0.574219 5.86402 0.574219 12.7676C0.574219 19.6711 6.17066 25.2676 13.0742 25.2676ZM14.9056 4.81177C15.8571 4.81177 16.5491 5.43891 16.5491 6.43369C16.5491 7.38523 15.7922 8.48814 14.4515 8.48814C13.5215 8.48814 12.8295 7.88262 12.8295 6.88783C12.8295 5.9363 13.5864 4.81177 14.9056 4.81177ZM13.3702 9.82893C14.5812 9.82893 15.4246 10.6507 15.4246 11.8834C15.4246 12.8559 14.878 14.2749 14.3918 15.5374C14.0022 16.549 13.6513 17.46 13.6513 17.9602C13.6513 18.2846 13.8243 18.3927 14.0406 18.3927C14.4298 18.3927 14.8623 18.0034 15.5544 16.295L16.6789 16.6843C15.8139 19.1496 14.4947 20.4039 12.8728 20.4039C11.5969 20.4039 10.667 19.6037 10.667 18.263C10.667 17.2715 11.228 15.8407 11.7228 14.579C12.1129 13.5839 12.4619 12.694 12.4619 12.2078C12.4619 11.8834 12.3321 11.7969 12.1375 11.7969C11.6834 11.7969 11.186 12.6403 10.5805 13.9378L9.47754 13.5053C10.4723 11.1697 11.6617 9.82893 13.3702 9.82893Z"
								/>
							</svg>
						</span>
						<h1 className="font-normal text-base text-color-6 tracking-[0.01em] leading-5">
							Gnosis Safe is the default wallet for your all transactions. To change this, simply disconnect through the
							Gnosis Safe drop-down.
						</h1>
					</div>
					<Button onClick={onClose}>Continue</Button>
				</div>
			) : (
				<>
					<div className="w-full overflow-y-auto max-h-[20rem] flex flex-col divide-y divide-color-4 border border-color-4 rounded-2xl my-8">
						<h1 className="text-center w-full p-4 text-xl font-semibold text-color-8">Select safe to connect</h1>
						{safes?.length ? (
							safes.map((safe) => (
								<Row
									onClick={() => {
										if (selectedSafe === safe) {
											setSelectedSafe(null)
										} else {
											setSelectedSafe(safe)
										}
									}}
									selected={selectedSafe === safe}
									address={safe}
									key={safe}
								>
									{safe}
								</Row>
							))
						) : (
							<div className="w-full h-40 grid place-items-center text-2xl text-color-6">Could not find any safe</div>
						)}
					</div>
					<Button
						disabled={!selectedSafe}
						onClick={() => {
							setGnosisAddress(selectedSafe)
						}}
					>
						Connect
					</Button>
				</>
			)}
		</Modal>
	)
}

export default ChooseGnosisSafe

interface RowProps {
	address: string
	selected?: boolean
	onClick?: MouseEventHandler
}

const Row: FC<RowProps> = ({ address, selected, onClick }) => {
	return (
		<div
			className={clsx(
				'w-full px-4 py-3 flex justify-between items-center cursor-pointer smooth-hover',
				selected ? 'bg-color-6' : 'hover:bg-color-2'
			)}
			onClick={onClick}
		>
			<div className="flex items-center">
				<div className="border border-color-4 p-2 rounded-full">
					<svg
						width="36"
						height="36"
						viewBox="0 0 36 36"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="fill-black dark:fill-white"
					>
						<path d="M18.0068 0.361329C14.5453 0.359975 11.1612 1.39331 8.28247 3.33064C5.40372 5.26798 3.15964 8.02228 1.83407 11.2452C0.508492 14.4681 0.160964 18.0149 0.835437 21.4369C1.50991 24.8589 3.17609 28.0024 5.62323 30.4699C8.07038 32.9374 11.1886 34.618 14.5834 35.2992C17.9783 35.9803 21.4973 35.6314 24.6954 34.2966C27.8935 32.9618 30.627 30.701 32.5502 27.8002C34.4735 24.8994 35.5 21.4888 35.5 18C35.4996 13.3232 33.6567 8.838 30.3764 5.53039C27.096 2.22278 22.6468 0.363509 18.0068 0.361329ZM30.8148 18.9631H22.688C22.4459 20.1854 21.7581 21.272 20.7604 22.0084C19.7626 22.7449 18.5273 23.0778 17.298 22.9415C16.0688 22.8052 14.9348 22.2095 14.1198 21.2721C13.3047 20.3346 12.8678 19.1233 12.8952 17.8771C12.9225 16.631 13.4121 15.4404 14.2675 14.5401C15.1228 13.6399 16.2818 13.0954 17.5159 13.014C18.7499 12.9326 19.9694 13.3202 20.9339 14.1004C21.8984 14.8806 22.5378 15.9967 22.726 17.2287H30.808C31.0361 17.2287 31.255 17.32 31.4163 17.4827C31.5777 17.6453 31.6683 17.8659 31.6683 18.0959C31.6683 18.3259 31.5777 18.5464 31.4163 18.7091C31.255 18.8717 31.0361 18.9631 30.808 18.9631H30.8148Z" />
					</svg>
				</div>
				<div className="ml-2 mb-1">
					<h1 className={clsx('text-lg font-semibold', selected ? 'text-color-1' : 'text-color-8')}>
						{shortenAddress(address, 4)}
					</h1>
				</div>
			</div>
			<svg
				width="21"
				height="14"
				viewBox="0 0 21 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={clsx(selected ? 'stroke-color-2' : 'stroke-color-3')}
			>
				<path d="M13.5 1.5L19 7L13.5 12.5" strokeWidth="2" strokeLinecap="round" />
				<path d="M18.9417 7H1.21143" strokeWidth="2" strokeLinecap="round" />
			</svg>
		</div>
	)
}
