import { useEffect, useState } from 'react'

const useWhenMounted = (): boolean => {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	return mounted
}

export default useWhenMounted
