import clsx from 'clsx'
import { FC } from 'react'

// Stroke
export const LeftCaretIcon: FC<{ className: string }> = ({ className }) => (
	<svg className={className} width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.10254 1L2.10254 7L8.10254 13" strokeWidth="2" strokeLinecap="round" />
	</svg>
)

export const RightCaretIcon: FC<{ className: string }> = ({ className }) => (
	<LeftCaretIcon className={clsx('rotate-180', className)} />
)
