import { useAppContext } from '@components/app-context'
import { GnosisSafeDropdown, WalletDropdown } from '@components/chakra/Dropdowns'
import { ImageHoverSwitch } from '@components/common/ImageWrapper'
import { useConnectWalletContext } from '@components/contexts/ConnectWalletProvider'
import clsx from 'clsx'
import { checkIsEth } from 'helpers/utils'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import { FC, useState } from 'react'
import useCurrentProfile from '../../hooks/useCurrentProfile'
import useCurrentUser from '../../hooks/useCurrentUser'
import useWhenMounted from '../../hooks/useWhenMounted'

type Props = {
	noBanner?: boolean
}

const Header: FC<Props> = ({ noBanner }) => {
	/**
	 * State Hooks
	 */
	const [onHover, setOnHover] = useState(false)

	/**
	 * Custom or Query Hooks
	 */
	const { data: user } = useCurrentUser()
	const { data: userProfile } = useCurrentProfile()
	const { theme, setTheme } = useTheme()
	const { chainId } = useAppContext()

	/**
	 * Chakra Hooks
	 */
	const { onOpen: onConnectModalOpen } = useConnectWalletContext()
	const isEth = checkIsEth(chainId)
	const mounted = useWhenMounted()

	if (!mounted) return null

	return (
		<>
			<header
				className={clsx('top-0 w-full lg:flex items-center py-8 z-10 hidden bg-coindeskBlack', {
					absolute: noBanner,
				})}
			>
				<div className="w-full h-full flex flex-row justify-between items-center relative pr-10 pl-20">
					<div className="flex items-center w-2/3 gap-x-10">
						<div className="w-full lg:w-1/2"></div>
					</div>
					<div className="flex items-center justify-between gap-x-10">
						{user && userProfile ? (
							<div className="px-4 border border-darkWhite flex items-center rounded-full z-[1]">
								<GnosisSafeDropdown />
								<div className="pl-3 py-2 pr-4 border-r border-darkWhite rounded-full items-center flex flex-row">
									<WalletDropdown />
								</div>
								<div className="items-center flex flex-row pl-2">
									{isEth ? (
										<Image src="/images/eth.svg" height={17} width={17} />
									) : (
										<Image src="/images/polygon.svg" height={17} width={17} />
									)}
								</div>
							</div>
						) : (
							// Connect Wallet
							<div
								className={clsx(
									'px-4 py-2 border border-darkWhite hover:border-coindeskYellow flex items-center justify-center rounded-full text-base tracking-[0.01em] font-normal smooth-hover cursor-pointer',
									onHover ? 'text-coindeskYellow' : 'text-darkWhite'
								)}
								onClick={onConnectModalOpen}
								onMouseEnter={() => setOnHover(true)}
								onMouseLeave={() => setOnHover(false)}
							>
								Connect wallet
								<ImageHoverSwitch
									image={{
										lightHover: '/images/Wallets/light-hover-wallet.svg',
										darkHover: '/images/Wallets/dark-hover-wallet.svg',
										light: '/images/Wallets/light-wallet.svg',
										dark: '/images/Wallets/dark-wallet.svg',
									}}
									size={16}
									className="ml-2"
									customHover={onHover}
									setCustomHover={setOnHover}
								/>
							</div>
						)}

						{/* Switch Theme Options */}
						<button onClick={() => (theme === 'dark' ? setTheme('light') : setTheme('dark'))}>
							{theme === 'light' ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									className="cursor-pointer h-6 w-6 stroke-color-0 hover:stroke-color-1"
								>
									<path
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
									/>
								</svg>
							) : (
								<svg
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="cursor-pointer h-6 w-6 stroke-color-9 hover:stroke-color-1"
								>
									<path
										d="M9.17383 1.50049V3.81036M9.17383 14.1906V16.4999M14.4769 3.69743L12.844 5.33031M5.50365 12.6707L3.87077 14.3035M16.6738 9.00051H14.364M3.9837 9.00051H1.67383M14.4769 14.3035L12.844 12.6707M5.50365 5.33031L3.87077 3.69743M14.2421 9.00048C14.2421 11.7996 11.9729 14.0687 9.17382 14.0687C6.37469 14.0687 4.10555 11.7996 4.10555 9.00048C4.10555 6.20135 6.37469 3.93221 9.17382 3.93221C11.9729 3.93221 14.2421 6.20135 14.2421 9.00048Z"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
									/>
								</svg>
							)}
						</button>
					</div>
				</div>
			</header>
		</>
	)
}

export default Header
