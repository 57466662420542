import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import { FC, MouseEventHandler, useState } from 'react'

interface ImageWrapperProps {
	lightImage: string
	darkImage: string
	rounded?: boolean
	span?: string
	customStyle?: string
}

const ImageWrapper: FC<ImageWrapperProps> = ({
	lightImage,
	darkImage,
	rounded,
	span = 'col-span-1',
	customStyle = 'mr-4 w-8 h-8 ',
}) => {
	const { theme } = useTheme()

	return (
		<div className={clsx('relative', rounded && 'rounded-full overflow-hidden', span, customStyle)}>
			{theme === 'light' ? (
				<Image src={lightImage} layout="fill" objectFit="contain" />
			) : (
				<Image src={darkImage} layout="fill" objectFit="contain" />
			)}
		</div>
	)
}

interface FixedImageWrapperProps {
	lightImage: string
	darkImage: string
	className?: string
	width: number
	height: number
	onClick?: () => void
	imageClassName?: string
}

export const FixedImageWrapper: FC<FixedImageWrapperProps> = ({
	lightImage,
	darkImage,
	className,
	width,
	height,
	onClick,
	imageClassName,
}) => {
	const { theme } = useTheme()

	return (
		<div className={className}>
			{theme === 'light' ? (
				<Image className={imageClassName} onClick={onClick} src={lightImage} width={width} height={height} />
			) : (
				<Image className={imageClassName} onClick={onClick} src={darkImage} width={width} height={height} />
			)}
		</div>
	)
}

export const ProfileImage: FC<{ image: string; className?: string; size?: string }> = ({
	image,
	size = 'w-10 h-10',
	className,
}) => {
	return (
		<div className={clsx('rounded-full overflow-hidden relative', size, className)}>
			<Image src={image} layout="fill" objectFit={'cover'} />
		</div>
	)
}

interface ImageHoverSwitchProps {
	// Note : Order of passing image 1> hover light 2> hover dark 3> light 4> dark
	image: { lightHover: string; darkHover: string; light: string; dark: string }
	size: number
	className?: string
	customHover?: boolean
	setCustomHover?: (state: boolean) => void
	onClick?: MouseEventHandler
}

// -------------- Pass Images that needs to be changed on Hover (See above for the order) ---------------

export const ImageHoverSwitch: FC<ImageHoverSwitchProps> = ({
	image,
	size,
	className,
	customHover,
	setCustomHover,
	onClick,
}) => {
	const [hover, setHover] = useState(false)

	return (
		<motion.div
			onHoverStart={setCustomHover ? () => setCustomHover(true) : () => setHover(true)}
			onHoverEnd={setCustomHover ? () => setCustomHover(false) : () => setHover(false)}
			className={className}
			onClick={onClick}
		>
			{customHover || hover ? (
				<FixedImageWrapper
					lightImage={image.lightHover}
					darkImage={image.darkHover}
					width={size}
					height={size}
					className="mt-1"
				/>
			) : (
				<FixedImageWrapper
					lightImage={image.light}
					darkImage={image.dark}
					width={size}
					height={size}
					className="mt-1"
				/>
			)}
		</motion.div>
	)
}

export const ImageCoverWrapper: FC<{ lightImage: string; darkImage: string }> = ({ lightImage, darkImage }) => {
	const { theme } = useTheme()

	if (theme === 'light') return <Image src={lightImage} layout="fill" objectFit="contain" priority />
	else return <Image src={darkImage} layout="fill" objectFit="contain" priority />
}

export default ImageWrapper
