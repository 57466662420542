import { FC } from 'react'

// Fill
export const NotificationBellIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 22 25" xmlns="http://www.w3.org/2000/svg">
			<path d="M11 24.5a3.436 3.436 0 0 0 3.25-2.306h-6.5A3.436 3.436 0 0 0 11 24.5Zm8.072-9.487V9.725c0-3.71-2.52-6.835-5.933-7.774A2.295 2.295 0 0 0 11 .5c-.975 0-1.801.6-2.139 1.45-3.413.942-5.933 4.065-5.933 7.775v5.288L.96 16.982a1.148 1.148 0 0 0-.338.815v1.153a1.153 1.153 0 0 0 1.153 1.153h18.45a1.153 1.153 0 0 0 1.153-1.153v-1.153a1.15 1.15 0 0 0-.338-.816l-1.968-1.968Z" />
		</svg>
	)
}

// Fill
// Unread dot remains red, but fill color of bell can be modified
export const NotificationBellUnreadIcon: FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#a)">
				<path d="M13.05 23.866a3.437 3.437 0 0 1-1.993.634 3.436 3.436 0 0 1-3.25-2.306h6.5a3.436 3.436 0 0 1-1.257 1.672ZM19.129 14.835v.178l1.968 1.969a1.147 1.147 0 0 1 .338.815v1.153a1.153 1.153 0 0 1-1.153 1.153H1.832A1.153 1.153 0 0 1 .68 18.95v-1.153a1.15 1.15 0 0 1 .338-.816l1.968-1.968V9.725c0-3.71 2.52-6.833 5.933-7.774A2.295 2.295 0 0 1 11.058.5c.975 0 1.8.6 2.138 1.45A8.089 8.089 0 0 1 16.735 4a5.599 5.599 0 0 0 2.394 10.835Z" />
				<circle cx="19.128" cy="9.257" r="4.193" fill="#FF806F" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	)
}
