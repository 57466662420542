import { ethers } from 'ethers'
import { useQuery, UseQueryResult } from 'react-query'
import { useAppContext } from '../components/app-context'

interface UseBalanceStringConfig {
	gnosis?: boolean
}

const useBalanceString = ({ gnosis = false }: UseBalanceStringConfig = {}): UseQueryResult<string> => {
	const { walletProvider, safeSdk } = useAppContext()

	return useQuery(
		['balance-string', { gnosis }],
		async (): Promise<string> => {
			const signer = gnosis ? safeSdk : walletProvider.getSigner()
			const fullEthArr = ethers.utils.formatEther(await signer.getBalance()).split('.')
			fullEthArr[1] = fullEthArr[1].slice(0, 2)
			return fullEthArr.join('.')
		},
		{
			enabled: Boolean(gnosis ? safeSdk : walletProvider),
		}
	)
}

export default useBalanceString
