import useWindowSize from '@hooks/useWindowSize'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useAppContext } from '../components/app-context'
import { getAddChainParams } from '../helpers/utils'

/**
 * @returns Function to prompt user to add/change to a particular chain
 */
const useAddOrChangeChain = (): ((chainId: number) => Promise<void>) => {
	const { walletProvider } = useAppContext()
	const router = useRouter()
	const [, width] = useWindowSize()
	const isMobile = width < 768

	return useCallback(
		async (chainId) => {
			if (!walletProvider || !chainId) return

			const params = getAddChainParams(chainId)
			if (!params) return

			/**
			 * @see {https://docs.metamask.io/guide/rpc-api.html#unrestricted-methods}
			 */
			try {
				await walletProvider.send('wallet_switchEthereumChain', [{ chainId: params.chainId }])
				router.reload()
			} catch (switchError) {
				if (switchError?.code === 4902 || isMobile) {
					try {
						await walletProvider.send('wallet_addEthereumChain', [params])

						router.reload()
					} catch (addError) {
						// Handle "add" error or user rejected
						console.error('ADD CHAIN ERROR', addError)
					}
				}
			}
		},
		[router, walletProvider, isMobile]
	)
}

export default useAddOrChangeChain
